import React from 'react'
import styles from './phnumber.module.css'

const PHnumber = () => {
  return (
    <div className={styles.container}>
    
    <div className={styles.box}>
      <div className={styles.Number}>
      <p className={styles.call}>CALL US ON +91 9986793055</p>
   
       
        </div>
       
        </div>
        </div>
  )
}

export default PHnumber
