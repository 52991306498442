import React from 'react'
import styles from './service.module.css'
import Products from '../../smallComponents/products/Products'

const ServiceListing = () => {
  return (
    <>
    <div className={styles.home}>
      <h2>Seamless event rentals in Bengaluru: Projectors, Speakers, Lightings, Stages, DJ, Catering and more with Shriram Visuals</h2>
   
    
    </div>
    <Products />
    </>
  )
}

export default ServiceListing
