import React from 'react'
import styles from './brands.module.css'
const Brands = () => {
  return (
    <div className={styles.container}>
      <h2>Trusted by more than 50+ brands</h2>
      <div className={styles.wrapper}>
        <div className={styles.slider}>
          <div className={styles.slidetrack}>
            <div className={styles.slide}>
              <img className={styles.hide} src="./assets/images/left.png" alt="srv" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/akkodis.webp" alt="srv" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/anandraith.webp" alt="brand" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/appito.webp" alt="app" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/asianpaints.webp" alt="asianpaints" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/capgemini.webp" alt="capgemini" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/cycle.webp" alt="cycle" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/federal.webp" alt="federal" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/fortis.webp" alt="fortis" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/fractal.webp" alt="fractal" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/glen.webp" alt="glen" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/icai.webp" alt="icai" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/ingersoll.webp" alt="ingersoll" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/jumbo.webp" alt="jumbo" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/kauvery.webp" alt="kauvery" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/kazi.webp" alt="kazi" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/kotak.webp" alt="kotak" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/lions.webp" alt="lions" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/little.webp" alt="little" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/mossadams.webp" alt="mossadams" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/musafir.webp" alt="musafir" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/oracle.webp" alt="oracle" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/relaince.webp" alt="relaince" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/simple.webp" alt="simple" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/tmi.webp" alt="tmi" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/vision.webp" alt="vision" />
            </div>
            <div className={styles.slide}>
              <img src="./assets/brands/torq.webp" alt="torq" />
            </div>
            <div className={styles.slide}>
              <img className={styles.hide} src="./assets/images/right.png" alt="trust" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Brands
